import Vue from 'vue'
import Vuetify from 'vuetify'
import '../node_modules/vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

const opts = {
  options: {
    customProperties: true,
  },
  theme: {
    themes: {
      light: {
        accent: '#FFFFFF',
        appbackground: '#F2F2F2',
        nav: '#FEFEFE',
        darkgrey: '#BDBDBD',
        darktext: '#4F4F4F',
        error: '#E10203',
        footer: '#FFFFFF',
        iconColorState: 'b3b3b3',
        info: '#ffd000',
        lightgrey: '#E0E0E0',
        lighttext: '#FFFFFF',
        header: '#FFFFFF',
        primary: '#04cfff',
        secondary: '#BDBDBD',
        draft: '#673ab7',
        sent: '#2699ff',
        completed: '#04cfff',
        rejected: '#ff7f50',
        accepted: '#ffc107',
        inprogress: '#4caf50',
        dispute: '#ff5656',
        escalated: '#ed143d',
        success: '#00942A',
        successIcon: '#00DF3F',
        tertiary: '#D7DDE2',
        vindicoPurple: '#582468',
        warning: '#E04B0D',
      },
      dark: {
        accent: '#4F4F4F',
        appbackground: '# 333333',
        nav: '#4f4f4f',
        darkgrey: '#4f4f4f',
        darktext: '#FFFFFF',
        error: '#db1f1f',
        iconColorState: 'b3b3b3',
        info: '#ffd000',
        lightgrey: '#4f4f4f',
        lighttext: '#4f4f4f',
        primary: '#04cfff',
        secondary: '#FCD72E',
        success: '#00b533',
        vindicoPurple: '#582468',
        warning: '#E04B0D',
      },
    },
  },
  breakpoint: {
    thresholds: {
      xs: 340,
      sm: 600,
      md: 800,
      lg: 1500,
    },
    scrollBarWidth: 24,
  },
}

export default new Vuetify(opts)
