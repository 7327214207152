import { mapActions } from 'vuex';

const progressBar_mixin = {
    methods: {
        ...mapActions('progressBar_store', ['ACT_progressBar']),
        MIX_progressBar(payload) {
            this.ACT_progressBar(payload);
        }
    }
}

export default {
    install(Vue) {
        Vue.mixin(progressBar_mixin)
    }
};




