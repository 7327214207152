<template>
    <v-app>
        <alertbox />
        <v-app-bar app color="header" clipped-left>
            <v-toolbar-title>
                <div class="d-flex">
                    <img 
                        class="logo"
                        @click="MIX_go('/home')"
                        :src="require('@/assets/tiqi.png')"/>
                </div>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <div class='white--text'>
                v{{ APP_VERSION }}
            </div>
        </v-app-bar>
        <Navigation />
        <main class="appbackground">
            <router-view />
        </main>
    </v-app>
</template>

<script>
import { version } from "../package.json";
export default {
    name: "App",
    data: () => ({
        APP_VERSION: version
    }),
    mounted() {
        let t = this;
        if (t.GET_currentUser === null) {
            t.MIX_go("/");
        }
    },
};
</script>

<style scoped>

main {
    padding: 4em 0 0 4em;
}
.logo {
    height: 40px;
}
</style>
