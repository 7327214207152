<template>
    <v-container fluid>
        <v-row>
            <v-col
                cols="2"
                class="d-flex flex-column align-start justify-start"
            >
                <v-card
                    flat
                    width="100%"
                    class="
                        d-flex
                        flex-column
                        align-center
                        justify-center
                        px-2
                        pb-8
                        rounded-0
                        light-blue
                        lighten-1
                    "
                >
                    <v-card-title class="white--text">
                        Average Job Length
                    </v-card-title>
                    <div class="text-h4 white--text text-center">
                        {{ getAverageJobLength }} days
                    </div>
                </v-card>
                <v-card
                    flat
                    width="100%"
                    class="
                        d-flex
                        flex-column
                        align-center
                        justify-center
                        pa-2
                        pb-8
                        rounded-0
                        light-blue
                        darken-3
                    "
                >
                    <v-card-title class="white--text"
                        >Average Job Cost</v-card-title
                    >
                    <div class="text-h4 white--text">
                        {{ getAverageJobCost }}
                    </div>
                </v-card>
                <v-card
                    flat
                    width="100%"
                    class="
                        d-flex
                        flex-column
                        align-center
                        justify-center
                        pa-2
                        pb-8
                        rounded-0
                        light-blue
                        darken-4
                    "
                >
                    <v-card-title class="white--text"
                        >Average Job Rating</v-card-title
                    >
                    <div class="text-h4 white--text">
                        {{ getAverageJobRating }}/5
                    </div>
                </v-card>
                <v-card
                    flat
                    width="100%"
                    class="
                        d-flex
                        flex-column
                        align-center
                        justify-center
                        pa-2
                        pb-8
                        rounded-0
                        info
                        darken-1
                    "
                >
                    <v-card-title class="white--text"
                        >Jobs Not Rated</v-card-title
                    >
                    <div class="text-h4 white--text">
                        {{ getJobsNotRated }}
                    </div>
                </v-card>
                <v-card
                    flat
                    width="100%"
                    class="
                        d-flex
                        flex-column
                        align-center
                        justify-center
                        pa-2
                        pb-8
                        rounded-0
                        dispute
                        darken-2
                    "
                >
                    <v-card-title class="white--text"
                        >Jobs Disputed</v-card-title
                    >
                    <div class="text-h4 white--text">
                        {{ getJobsDisputed }}
                    </div>
                </v-card>
                <v-card
                    flat
                    width="100%"
                    class="
                        d-flex
                        flex-column
                        align-center
                        justify-center
                        pa-2
                        pb-8
                        rounded-0
                        escalated
                        darken-2
                    "
                >
                    <v-card-title class="white--text"
                        >Jobs Escalated</v-card-title
                    >
                    <div class="text-h4 white--text">
                        {{ getJobsEscalated }}
                    </div>
                </v-card>
            </v-col>
            <v-col cols="6">
                <div class="lightgrey pa-2 mb-7">
                    <apexchart
                        type="area"
                        height="350"
                        :options="visitByMonthOptions"
                        :series="visitByMonthSeries"
                    ></apexchart>
                </div>
                <div class="lightgrey pa-2">
                    <apexchart
                        type="pie"
                        width="600"
                        ref="jobCounts"
                        :options="jobCountOptions"
                        :series="jobCountSeries"
                    ></apexchart>
                </div>
            </v-col>
            <v-col cols="3">
                <div class="lightgrey pa-2 mb-7">
                    <apexchart
                        type="bar"
                        height="350"
                        ref="jobCountMonth"                        
                        :options="jobCountMonthOptions"
                        :series="jobCountMonthSeries"
                    ></apexchart>
                </div>
                <v-card
                    flat
                    width="100%"
                    class="
                        d-flex
                        flex-column
                        align-center
                        justify-center
                        pa-5
                        pb-8
                        rounded-0
                        lightgrey
                    "
                >
                    <v-card-title class="grey--text"
                        >Accounts ({{ getUserCount }})</v-card-title
                    >
                    <div class="text-h4 darkgrey--text">
                        {{ getTraderCount }} traders
                    </div>
                    <div class="text-h4 darkgrey--text">
                        {{ getBuyerCount }} buyers
                    </div>
                </v-card>   
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import apexCharts from "./apexCharts.js";
export default {
    name: "Home",
    data: () => ({
        jobs: [],
        users: [],
        //* Chart settings for visit by month
        visitByMonthOptions: apexCharts.visitByMonthOptions,
        visitByMonthSeries: apexCharts.visitByMonthSeries,
        //* Chart settings for job counts
        jobCountOptions: apexCharts.jobCountOptions,
        jobCountSeries: apexCharts.jobCountSeries,
        //* Chart settings for job count this month
        jobCountMonthOptions: apexCharts.jobCountMonthOptions,
        jobCountMonthSeries: apexCharts.jobCountMonthSeries,
    }),
    computed: {
        ...mapGetters({
            GET_currentUser: "GET_currentUser",
            GET_config: "GET_config",
        }),
        // getJobs
        getUserCount() {
            return Object.keys(this.users).length;
        },
        getTraderCount() {
            return this.users.filter((user) => user.userType === "Trader")
                .length;
        },
        getBuyerCount() {
            return this.users.filter((user) => user.userType === "Buyer")
                .length;
        },
        getAverageJobLength() {
            return Math.round(this.jobs.reduce((total, job) => {
                return total + (parseFloat(job.length) ? parseFloat(job.length) : 0);
            }, 0) / this.jobs.length)
        },
        getAverageJobCost() {
            const totalJobCost = this.jobs.reduce((total, job) => total + (parseFloat(job.cost ?? 0) ? parseFloat(job.cost ?? 0) : 0), 0);
            return(parseFloat(totalJobCost / this.jobs.length).toLocaleString("en-UK", {style:"currency", currency:"GBP"}));
        },
        getAverageJobRating() {
            const totalJobRating = this.jobs.reduce((total, job) => total + (parseFloat(job.totalRating?.rating ?? 0) ? parseFloat(job.totalRating?.rating ?? 0) : 0), 0);
            return ((totalJobRating / this.jobs.length).toPrecision(3));
        },
        getJobsNotRated() {
            return this.jobs.filter(job => job.dateRated === "").length
        },
        getJobsEscalated() {
            return this.jobs.filter(job => job.status === "escalated").length
        },
        getJobsDisputed() {
            return this.jobs.filter(job => job.status === "dispute").length
        },
    },
    methods: {
        ...mapActions({
            ACT_setConfig: "ACT_setConfig",
        }),
        retrieveTiqiConfig: async function () {
            let t = this;
            t.$firebase.db.collection("config").onSnapshot((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    var config = doc.data();
                    t.ACT_setConfig(config);
                });
            });
        },
        getJobs() {
            let t = this;
            t.$firebase.db.collection("jobs").onSnapshot((querySnapshot) => {
                t.jobs = [];
                querySnapshot.forEach((doc) => {
                    var job = doc.data();
                    job.id = doc.id;
                    if(!job.delete && !job.archived) {
                        t.jobs.push(job);
                    }
                });
            });
        },
        getAllUsers() {
            let t = this;
            t.$firebase.db
                .collection("users")
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        t.users.push(doc.data());
                    });
                });
        },
        getJobCounts() {
            const newJobs = this.jobs.filter((item) => item.status === "new" || item.status === "sent").length;
            const acceptedJobs = this.jobs.filter((item) => item.status === "accepted").length;
            const completedJobs = this.jobs.filter((item) => item.status === "completed").length;
            const inprogressJobs = this.jobs.filter((item) => item.status === "inprogress").length;
            const disputeJobs = this.jobs.filter((item) => item.status === "dispute").length;
            return this.$refs.jobCounts.updateSeries([newJobs, acceptedJobs, completedJobs, inprogressJobs, disputeJobs]);
        },
        getJobCountMonth() {
            const thisMonth = this.$moment().format('MMMM')
            const returnedResult = this.jobs.filter(job => { 
                const timestamp = this.$moment(job.createdDateTime, 'X').format('MMMM')
                return timestamp === thisMonth
            })
            return this.$refs.jobCountMonth.updateSeries([
                {
                    name: "Job Count",
                    data: [returnedResult.length],
                },
            ])
        },
        setChartData() {
            setTimeout(() => {
                this.getJobCounts();
                this.getJobCountMonth();
            }, 2000)
        }
    },
    mounted() {
        let t = this;
        t.retrieveTiqiConfig();
        t.getJobs();
        t.getAllUsers();
        t.setChartData();
    },
};
</script>
