<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" class="d-flex">
                <v-icon
                    size="40"
                    position="left"
                    color="primary"
                    class="align-center"
                    >tiqi-idea</v-icon
                >
                <div class="text-h4 font-weight-light primary--text pl-4">
                    Setup
                </div>
            </v-col>
        </v-row>
        <v-row>
            <SetupComponent />
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import SetupComponent from "../components/SetupComponent";

export default {
    name: "Setup",
    data: () => ({}),
    components: { SetupComponent },
    computed: {
        ...mapGetters({
            GET_currentUser: "GET_currentUser",
        }),
    },
};
</script>