<template>
  <div class="home viewport-maxwidth">
    <!-- start of USER-INPUT -->
    <v-row class="mt-0 justify-center">
      <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
        <v-form>
          <v-text-field
            ref="email"
            solo
            v-model.trim="email"
            label="Email"
            append-icon="icons8-envelope"
            hide-details="auto"
            data-vv-name="email"
            required
            :rules="[rules.required, rules.email]"
          ></v-text-field>
          <p class="mt-4">
            <center>
              <v-btn class="primary" @click.native="resetPassword()">Send reset password link</v-btn>
            </center>
          </p>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'ResetPassword',
  data: () => ({
    email: '',
    rules: {
      required: value => !!value || 'This filed is required.',
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Invalid e-mail. Pleas try again '
      },
    },
  }),
  methods: {
    resetPassword: function() {
      let t = this
      t.email = t.email.toLowerCase()
      if (t.$refs.email.validate()) {
        t.$firebase.auth
          .sendPasswordResetEmail(t.email)
          .then(function() {
            // Email sent.
            //console.log("email sent');
            t.MIX_alert(1, 'A link to reset your password has been sent to your email address. Check your inbox.', null, null)
          })
          .catch(function(error) {
            //console.log('err: ' + error);
            t.MIX_alert(-1, error, null, null)
          })
      } else {
        t.MIX_alert(-1, 'Fill out your email correctly field in order to proceed.', null, null)
      }
    },
  },
}
</script>
