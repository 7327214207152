import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import firebase_auth_store from '@/modules/firebase/firebase_auth_store.js';
import progressBar_store from "@/components/progressBar/progressBar_store.js";
import alertbox_store from '@/modules/alertbox/alertbox_store.js'

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [createPersistedState()],
    modules: {
        firebase_auth_store: firebase_auth_store,
        progressBar_store: progressBar_store,
        alertbox_store: alertbox_store,
    },
    state: {
        allUsers: [],
        config: {},
        currentUser: null,
    },
    mutations: {
        MUT_allUsers(state, payload) { state.allUsers = payload },
        MUT_currentUser(state, payload) { state.currentUser = payload },
        MUT_setConfig(state, payload) { state.config = payload },
    },
    actions: {
        ACT_allUsers({ commit }, payload) { commit('MUT_allUsers', payload) },
        ACT_currentUser({ commit }, payload) { commit('MUT_currentUser', payload) },
        ACT_setConfig({ commit }, payload) { commit('MUT_setConfig', payload) },
    },
    getters: {
        GET_allUsers(state) { return state.allUsers },
        GET_currentUser(state) { return state.currentUser },
        GET_config(state) { return state.config }
    }
})
