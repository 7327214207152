const progressBar_store = {
    namespaced: true, //to be case sencitive
    // * STATE
    state: {
        progressBar: {
            show: false,
        },
    },
    // * MUTATIONS
    mutations: {
        MUT_progressBar(state, payload) { state.progressBar = payload }
    },
    // * GETTERS
    getters: {
        GET_progressBar(state) { return state.progressBar }
    },
    // * Actions
    actions: {
        ACT_progressBar({ commit }, payload) { commit('MUT_progressBar', payload) },

    }
};
export default progressBar_store;