<template>
    <v-container fluid>
        <v-col cols="12">
            <v-row class="d-flex align-center justify-center">
                <v-card
                    @click="filterStatus='draft'"
                    class="my-2 mx-2"
                    :class="countDraft === 0 ? 'darkgrey' : 'draft'"
                    width="150"
                >
                    <v-card-text class="text-h3 white--text text-center">{{
                        countDraft
                    }}</v-card-text>
                    <v-card-text
                        class="
                            body-1
                            font-weight-bold
                            text-center
                            white--text
                            pt-0
                        "
                        >DRAFT</v-card-text
                    >
                </v-card>
                <v-card
                    class="my-2 mx-2"
                    @click="filterStatus='sent'"
                    :class="countSent === 0 ? 'darkgrey' : 'sent'"
                    width="150"
                >
                    <v-card-text class="text-h3 white--text text-center">{{
                        countSent
                    }}</v-card-text>
                    <v-card-text
                        class="
                            body-1
                            font-weight-bold
                            text-center
                            white--text
                            pt-0
                        "
                        >SENT</v-card-text
                    >
                </v-card>
                <v-card
                    @click="filterStatus='inprogress'"
                    class="my-2 mx-2"
                    :class="countInProgress === 0 ? 'darkgrey' : 'inprogress'"
                    width="150"
                >
                    <v-card-text class="text-h3 white--text text-center">{{
                        countInProgress
                    }}</v-card-text>
                    <v-card-text
                        class="
                            body-1
                            font-weight-bold
                            text-center
                            white--text
                            pt-0
                        "
                        >IN PROGRESS</v-card-text
                    >
                </v-card>
                <v-card
                    @click="filterStatus='accepted'"
                    class="my-2 mx-2"
                    :class="countAccepted === 0 ? 'darkgrey' : 'accepted'"
                    width="150"
                >
                    <v-card-text class="text-h3 white--text text-center">{{
                        countAccepted
                    }}</v-card-text>
                    <v-card-text
                        class="
                            body-1
                            font-weight-bold
                            text-center
                            white--text
                            pt-0
                        "
                        >ACCEPTED</v-card-text
                    >
                </v-card>
                <v-card
                    @click="filterStatus='rejected'"
                    class="my-2 mx-2"
                    :class="countRejected === 0 ? 'darkgrey' : 'rejected'"
                    width="150"
                >
                    <v-card-text class="text-h3 white--text text-center">{{
                        countRejected
                    }}</v-card-text>
                    <v-card-text
                        class="
                            body-1
                            font-weight-bold
                            text-center
                            white--text
                            pt-0
                        "
                        >REJECTED</v-card-text
                    >
                </v-card>
                <v-card
                    @click="filterStatus='completed'"
                    class="my-2 mx-2"
                    :class="countCompleted === 0 ? 'darkgrey' : 'completed'"
                    width="150"
                >
                    <v-card-text class="text-h3 white--text text-center">{{
                        countCompleted
                    }}</v-card-text>
                    <v-card-text
                        class="
                            body-1
                            font-weight-bold
                            text-center
                            white--text
                            pt-0
                        "
                        >COMPLETED</v-card-text
                    >
                </v-card>
                <v-card
                    @click="filterStatus='dispute'"
                    class="my-2 mx-2"
                    :class="countDispute === 0 ? 'darkgrey' : 'dispute'"
                    width="150"
                >
                    <v-card-text class="text-h3 white--text text-center">{{
                        countDispute
                    }}</v-card-text>
                    <v-card-text
                        class="
                            body-1
                            font-weight-bold
                            text-center
                            white--text
                            pt-0
                        "
                        >DISPUTE</v-card-text
                    >
                </v-card>
                <v-card
                    @click="filterStatus='escalated'"
                    class="my-2 mx-2"
                    :class="countEscalated === 0 ? 'darkgrey' : 'escalated'"
                    width="150"
                >
                    <v-card-text class="text-h3 white--text text-center">{{
                        countEscalated
                    }}</v-card-text>
                    <v-card-text
                        class="
                            body-1
                            font-weight-bold
                            text-center
                            white--text
                            pt-0
                        "
                        >ESCALATED</v-card-text
                    >
                </v-card>
                <v-card
                    class="my-2 mx-2"
                    :class="countDeleted === 0 ? 'darkgrey' : 'grey'"
                    width="150"
                >
                    <v-card-text class="text-h3 white--text text-center">{{
                        countDeleted
                    }}</v-card-text>
                    <v-card-text
                        class="
                            body-1
                            font-weight-bold
                            text-center
                            white--text
                            pt-0
                        "
                        >DELETED</v-card-text
                    >
                </v-card>
                <v-card
                    class="my-2 mx-2"
                    :class="countArchive === 0 ? 'darkgrey' : 'grey'"
                    width="150"
                >
                    <v-card-text class="text-h3 white--text text-center">{{
                        countArchive
                    }}</v-card-text>
                    <v-card-text
                        class="
                            body-1
                            font-weight-bold
                            text-center
                            white--text
                            pt-0
                        "
                        >ARCHIVED</v-card-text
                    >
                </v-card>
            </v-row>
            <v-row class="d-flex align-center justify-space-between pt-4">
                <v-col cols="2" class="pl-0">
                    <v-text-field
                        solo
                        elevation="0"
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-col>
                <v-col cols="2">
                    <v-select
                        solo
                        elevation="0"
                        :items="status"
                        label="Status"
                        v-model="filterStatus"
                        single-line
                        hide-details
                        clearable
                    ></v-select>
                </v-col>
                <v-col cols="2">
                    <v-select
                        solo
                        elevation="0"
                        :items="filterTraders"
                        label="Trader"
                        v-model="selectedTrader"
                        single-line
                        hide-details
                        clearable
                    ></v-select>
                </v-col>
                <v-col cols="2">
                    <v-select
                        solo
                        elevation="0"
                        :items="filterBuyers"
                        label="Buyer"
                        v-model="selectedBuyer"
                        single-line
                        hide-details
                        clearable
                    ></v-select>
                </v-col>
                <v-btn class="primary" @click="filterStatus = status">
                    <v-icon
                        size="30"
                        color="white"
                        class="pr-2"
                        style="cursor: pointer !important"
                        >mdi-close</v-icon
                    >
                    Clear Filters
                </v-btn>
                <download-csv :data="computedJobs">
                    <v-btn class="primary mt-2"
                        >Download All Jobs (CSV)
                        <v-icon
                            size="30"
                            color="white"
                            class="pl-1"
                            style="cursor: pointer !important"
                            >tiqi-csv</v-icon
                        >
                    </v-btn>
                </download-csv>
            </v-row>
            <v-row class="text-center d-flex justify-center">
                <v-data-table
                    fixed-header
                    :headers="headers"
                    :items="computedJobs"
                    :items-per-page="10"
                    :search="search"
                    class="elevation-1 mt-6"
                >
                    <template v-slot:[`item.name`]="{ item }">
                        <span v-if="item.delete" class="red--text font-weight-bold">{{ item.name }}</span>
                        <span v-else>{{ item.name }}</span>
                    </template>
                    <template v-slot:[`item.status`]="{ item }">
                        <td
                            v-if="item !== null"
                            class="pa-2 body-2 text-uppercase font-weight-bold"
                            style="height: 8vh"
                            width="5%"
                            align="left"
                            valign="center"
                        >
                            <v-chip
                                small
                                class="white--text"
                                :class="item.status"
                                >{{ item.status }}</v-chip
                            >
                        </td>
                    </template>
                    <template v-slot:[`item.rating`]="{ item }">
                        <span>
                            <v-rating
                                v-if="item.totalRating.rating"
                                background-color="primary"
                                empty-icon="tiqi-star"
                                full-icon="tiqi-star-2"
                                readonly
                                length="5"
                                size="20"
                                :value="item.totalRating.rating"
                            ></v-rating>
                        </span>
                        <span v-if="item.dateRated">{{ $moment.unix(item.dateRated).format("DD/MM/YYYY HH:mm") }}</span>
                    </template>
                    <template v-slot:[`item.archived`]="{ item }">
                        <span>
                            <v-chip
                                v-if="item.archived"
                                small
                                class="white--text error"
                                >ARCHIVED</v-chip
                            >
                        </span>
                    </template>
                    <template v-slot:[`item.delete`]="{ item }">
                        <span>
                            <v-chip
                                v-if="item.delete"
                                small
                                class="white--text error"
                                >DELETED</v-chip
                            >
                        </span>
                    </template>
                    
                    <template v-slot:[`item.dateCompleted`]="{ item }">
                        <template v-if="item.dateCompleted">
                            <div>{{ $moment.unix(item.dateCompleted).format('HH:mm') }}</div>
                            <div><b>{{ $moment.unix(item.dateCompleted).format('DD/MM/YYYY') }}</b></div>
                            ({{ $moment.unix(item.dateCompleted).diff($moment.unix(item.dateStarted), 'days') }} days)
                        </template>
                    </template>

                    <template v-slot:[`item.client.name`]="{ item }">
                        <div>
                            <b>{{  item.client.name }}</b>
                        </div>
                        <div>
                            <a :href="`tel:${item.client.telephone}`">{{ item.client.telephone }}</a>
                        </div>
                        <div>
                            <a :href="`mailto:${item.client.email}`">{{ item.client.email }}</a>
                        </div>
                    </template>

                    <template v-slot:[`item.trader.businessName`]="{ item }">
                        <div>
                            <b>{{  item.trader.businessName }}</b>
                        </div>
                        <div>
                            {{  item.trader.userName }}
                        </div>
                        <div>
                            <a :href="`mailto:${item.trader.userEmail}`"> {{ item.trader.userEmail }}</a>
                        </div>
                    </template>

                    <template v-slot:[`item.cost`]="{ item }">
                        <span>
                            <b>{{
                            MIX_calculateFees(item).payoutValue ?
                                (MIX_calculateFees(item).payoutValue / 100) 
                                    .toLocaleString("en-UK", {
                                            style: "currency",
                                            currency: "GBP"
                                        }) : ''
                            }} 
                            </b>
                        </span>
                    </template>
                    <template v-slot:[`item.createdDateTime`]="{ item }">
                        <template v-if="item.createdDateTime">
                            <div>{{ $moment.unix(item.createdDateTime).format('HH:mm') }}</div>
                            <div><b>{{ $moment.unix(item.createdDateTime).format('DD/MM/YYYY') }}</b></div>
                        </template>
                    </template>

                    <template v-slot:[`item.dateRejected`]="{ item }">
                        <template v-if="item.dateRejected">
                            <div>{{ $moment.unix(item.dateRejected).format('HH:mm') }}</div>
                            <div><b>{{ $moment.unix(item.dateRejected).format('DD/MM/YYYY') }}</b></div>
                        </template>
                    </template>
                    
                    <template v-slot:[`item.dateAccepted`]="{ item }">
                        <template v-if="item.dateAccepted">
                            <div>{{ $moment.unix(item.dateAccepted).format('HH:mm') }}</div>
                            <div><b>{{ $moment.unix(item.dateAccepted).format('DD/MM/YYYY') }}</b></div>
                        </template>
                    </template>
                    
                    <template v-slot:[`item.dateStarted`]="{ item }">
                        <template v-if="item.dateStarted">
                            <div>{{ $moment.unix(item.dateStarted).format('HH:mm') }}</div>
                            <div><b>{{ $moment.unix(item.dateStarted).format('DD/MM/YYYY') }}</b></div>
                        </template>
                    </template>
                    
                    <template v-slot:[`item.dateDisputed`]="{ item }">
                        <template v-if="item.dateDisputed">
                            <div>{{ $moment.unix(item.dateDisputed).format('HH:mm') }}</div>
                            <div><b>{{ $moment.unix(item.dateDisputed).format('DD/MM/YYYY') }}</b></div>
                        </template>
                    </template>
                    
                    <template v-slot:[`item.dateEscalated`]="{ item }">
                        <template v-if="item.dateEscalated">
                            <div>{{ $moment.unix(item.dateEscalated).format('HH:mm') }}</div>
                            <div><b>{{ $moment.unix(item.dateEscalated).format('DD/MM/YYYY') }}</b></div>
                        </template>
                    </template>

                    <template v-slot:[`item.datePaymentReleased`]="{ item }">
                        <template v-if="item.datePaymentReleased">
                            <div>{{ $moment.unix(item.datePaymentReleased).format('HH:mm') }}</div>
                            <div><b>{{ $moment.unix(item.datePaymentReleased).format('DD/MM/YYYY') }}</b></div>
                        </template>
                    </template>


                    <template v-slot:[`item.actions`]="{ item, on, attrs }">
                        <v-btn
                            @click="openJob(item)"
                            depressed
                            class="primary--text white"
                            v-bind="attrs"
                            v-on="on"
                            >Open<v-icon>mdi-chevron-right</v-icon></v-btn
                        >
                    </template>
                </v-data-table>
                <!-- Start of side expansion panel -->
                <v-navigation-drawer
                    v-model="dialog"
                    fixed
                    app
                    right
                    width="600"
                >
                    <!-- REFUND USER OVERLAY -->
                    <v-overlay :value="refundUser" opacity="0.85">
                        <span @click="dialog=false">Close</span>
                        <v-col
                            cols="12"
                            class="d-flex flex-column align-center"
                        >
                            <div
                                class="
                                    text-h4
                                    error--text
                                    font-weight-bold
                                    pb-4
                                "
                            >
                                Warning!
                            </div>
                            <div class="body-1 pb-8">
                                Are you sure you want to refund funds to the
                                buyer? This action is irreversible!
                            </div>
                            <div class="body-1 pb-8">
                                Please confirm your password in order to proceed
                            </div>
                            <v-text-field
                                solo
                                elevation="0"
                                class="mb-12"
                                v-model.trim="userPassword"
                                label="Password"
                                :append-icon="show ? 'tiqi-eye' : 'tiqi-invisible'"
                                :type="show ? 'text' : 'password'"
                                @click:append="show = !show"
                                hide-details="auto"
                                v-on:blur="validatePassword()"
                            ></v-text-field>
                            <v-row>
                                <v-col cols="6">
                                    <v-btn
                                        block
                                        class="darkgrey"
                                        @click="refundUser = false"
                                        >Cancel</v-btn
                                    >
                                </v-col>
                                <v-col cols="6">
                                    <v-btn
                                        block
                                        class="primary"
                                        @click="releaseBuyerFunds()"
                                        :loading="loading"
                                        :disabled="releaseButton"
                                        >Release</v-btn
                                    >
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-overlay>
                    <!-- RESOLVE DISPUTE OVERLAY -->
                    <v-overlay :value="resolveDispute" opacity="0.85">
                        <v-col
                            cols="12"
                            class="d-flex flex-column align-center"
                        >
                            <div
                                class="
                                    text-h4
                                    error--text
                                    font-weight-bold
                                    pb-4
                                "
                            >
                                Warning!
                            </div>
                            <div class="body-1 pb-8">
                                Are you sure you want to resolve the dispute and
                                make the remaining funds available to the trader?
                                This action is irreversible.
                            </div>
                            <v-row>
                                <v-col cols="6">
                                    <v-btn
                                        block
                                        class="darkgrey"
                                        @click="resolveDispute = false"
                                        >Cancel</v-btn
                                    >
                                </v-col>
                                <v-col cols="6">
                                    <v-btn
                                        block
                                        class="primary"
                                        @click="resolveEscalation()"
                                        :loading="loading"
                                        :disabled="loading"
                                        >Resolve</v-btn
                                    >
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-overlay>
                    <!-- RESOLVE FIRST TIME JOB OVERLAY -->
                    <v-overlay :value="resolveFirstJob" opacity="0.85">
                        <v-col
                            cols="12"
                            class="d-flex flex-column align-center"
                        >
                            <div
                                class="
                                    text-h4
                                    error--text
                                    font-weight-bold
                                    pb-4
                                "
                            >
                                Warning!
                            </div>
                            <div class="body-1 pb-8">
                                Are you sure you want to payout to the trader? This action is
                                irreversible!
                            </div>
                            <v-row>
                                <v-col cols="6">
                                    <v-btn
                                        block
                                        class="darkgrey"
                                        @click="resolveFirstJob = false"
                                        >Cancel</v-btn
                                    >
                                </v-col>
                                <v-col cols="6">
                                    <v-btn
                                        block
                                        class="primary"
                                        @click="resolveFirstJobWait()"
                                        :loading="loading"
                                        :disabled="loading"
                                        >Release</v-btn
                                    >
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-overlay>
                    <!-- SPLIT PAYOUT OVERLAY -->
                    <v-overlay :value="splitPayout" opacity="0.95">
                        <v-col
                            cols="12"
                            class="d-flex flex-column align-center"
                        >
                            <div
                                class="
                                    text-h4
                                    error--text
                                    font-weight-bold
                                    pb-4
                                "
                            >
                                Warning!
                            </div>
                            <div class="body-1 pb-8">
                                Are you sure you want to split payout to the trader & buyer? This action is
                                irreversible!
                            </div>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        label="Buyer Fees (£)"
                                        v-model="buyerFees"
                                        outlined
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        label="Trader Fees (£)"
                                        v-model="traderFees"
                                        outlined
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-btn
                                        block
                                        class="darkgrey"
                                        @click="splitPayout = false"
                                        >Cancel</v-btn
                                    >
                                </v-col>
                                <v-col cols="6">
                                    <v-btn
                                        block
                                        class="primary"
                                        @click="splitFunds()"
                                        :loading="loading"
                                        :disabled="loading"
                                        >Release</v-btn
                                    >
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-overlay>
                    <v-card class="rounded-sm">
                        <v-toolbar
                            :color="job.status">
                            <v-row
                                class="
                                    d-flex
                                    align-center
                                    justify-space-between
                                    pa-4
                                "
                            >
                                <div
                                    class="
                                        text-h4
                                        font-weight-light
                                        white--text
                                    "
                                >
                                    Job: {{ job.name }}
                                </div>
                                <v-btn icon dark @click="dialog = false">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </v-row>
                        </v-toolbar>
                        <v-expansion-panels multiple accordion tile>
                            <!-- Job Info -->
                            <v-expansion-panel
                                class="rounded-sm"
                                @click="next()"
                            >
                                <v-expansion-panel-header class="lightgrey">
                                    <v-col cols="2">
                                        <v-icon
                                            size="30"
                                            position="left"
                                            color="primary"
                                            class="align-center"
                                            >tiqi-toolbox</v-icon
                                        >
                                    </v-col>
                                    <v-col cols="12">
                                        <div
                                            class="
                                                text-h6
                                                font-weight-regular
                                                darktext--text
                                                align-center
                                            "
                                        >
                                            Job Info
                                        </div>
                                    </v-col>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content class="py-4">
                                    <v-simple-table>
                                        <template v-slot:default>
                                            <tbody>
                                                <tr>
                                                    <td class="text-left">
                                                        Specification:
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td
                                                        class="text-right"
                                                        style="
                                                            overflow-wrap: anywhere !important;
                                                        "
                                                    >
                                                        {{ job.specification }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="text-left">
                                                        Status:
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td class="text-right">
                                                        <span
                                                            v-if="
                                                                job.status ===
                                                                'completed'
                                                            "
                                                            class="
                                                                success--text
                                                            "
                                                            >{{
                                                                job.status
                                                            }}</span
                                                        >
                                                        <span
                                                            v-if="
                                                                job.status ===
                                                                'inprogress'
                                                            "
                                                            class="
                                                                primary--text
                                                            "
                                                            >{{
                                                                job.status
                                                            }}</span
                                                        >
                                                        <span
                                                            v-if="
                                                                job.status ===
                                                                'dispute'
                                                            "
                                                            class="
                                                                warning--text
                                                            "
                                                            >{{
                                                                job.status
                                                            }}</span
                                                        >
                                                        <span
                                                            v-if="
                                                                job.status ===
                                                                'escalated'
                                                            "
                                                            class="error--text"
                                                            >{{
                                                                job.status
                                                            }}</span
                                                        >
                                                        <span
                                                            v-if="
                                                                job.status ===
                                                                'new'
                                                            "
                                                            class="
                                                                primary--text
                                                            "
                                                            >{{
                                                                job.status
                                                            }}</span
                                                        >
                                                        <span
                                                            v-if="
                                                                job.status ===
                                                                'sent'
                                                            "
                                                            class="
                                                                primary--text
                                                            "
                                                            >{{
                                                                job.status
                                                            }}</span
                                                        >
                                                        <span
                                                            v-if="
                                                                job.status ===
                                                                'accepted'
                                                            "
                                                            class="
                                                                success--text
                                                            "
                                                            >{{
                                                                job.status
                                                            }}</span
                                                        >
                                                        <span
                                                            v-if="
                                                                job.status ===
                                                                'cancelled'
                                                            "
                                                            class="error--text"
                                                            >{{
                                                                job.status
                                                            }}</span
                                                        >
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="text-left">
                                                        Rating:
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td
                                                        class="
                                                            d-flex
                                                            align-center
                                                            justify-end
                                                        "
                                                    >
                                                        <v-rating
                                                            background-color="primary"
                                                            empty-icon="tiqi-star"
                                                            full-icon="tiqi-star-2"
                                                            readonly
                                                            length="5"
                                                            size="20"
                                                            :value="job.rating"
                                                        ></v-rating>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="text-left">
                                                        Job Cost:
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td class="text-right">
                                                        £{{ job.cost }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="text-left">
                                                        Fee Paid:
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td class="text-right">
                                                        <pre>{{ job.feePaid }}</pre>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="text-left">
                                                        Start Date:
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td class="text-right">
                                                        {{ job.dateStarted }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="text-left">
                                                        End Date:
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td class="text-right">
                                                        {{ job.dateCompleted }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <!-- Parties -->
                            <v-expansion-panel>
                                <v-expansion-panel-header class="lightgrey">
                                    <v-col cols="2">
                                        <v-icon
                                            size="30"
                                            position="left"
                                            color="primary"
                                            class="align-center"
                                            >tiqi-group</v-icon
                                        >
                                    </v-col>
                                    <v-col cols="12">
                                        <div
                                            class="
                                                text-h6
                                                font-weight-regular
                                                darktext--text
                                                align-center
                                            "
                                        >
                                            Parties
                                        </div>
                                    </v-col>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content class="py-4">
                                    <v-simple-table>
                                        <template v-slot:default>
                                            <tbody>
                                                <tr>
                                                    <td class="text-left">
                                                        Buyer:
                                                    </td>
                                                    <td></td>
                                                    <td>
                                                        {{ job.client.name }}
                                                    </td>
                                                    <td></td>
                                                    <td class="text-right">
                                                        {{ job.client.email }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="text-left">
                                                        Trader:
                                                    </td>
                                                    <td></td>
                                                    <td>
                                                        {{
                                                            job.trader
                                                                .businessName
                                                        }}
                                                    </td>
                                                    <td></td>
                                                    <td class="text-right">
                                                        {{
                                                            job.trader.userEmail
                                                        }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <!-- Transactions -->
                            <v-expansion-panel>
                                <v-expansion-panel-header class="lightgrey">
                                    <v-col cols="2">
                                        <v-icon
                                            size="30"
                                            position="left"
                                            color="primary"
                                            class="align-center"
                                            >tiqi-transaction</v-icon
                                        >
                                    </v-col>
                                    <v-col cols="12">
                                        <div
                                            class="
                                                text-h6
                                                font-weight-regular
                                                darktext--text
                                                align-center
                                            "
                                        >
                                            Transactions
                                        </div>
                                    </v-col>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content class="py-4">
                                    <v-simple-table>
                                        <template v-slot:default>
                                            <tbody>
                                                <tr>
                                                    <td class="text-left">
                                                        <span>Job Cost</span>
                                                    </td>
                                                    <td>
                                                        {{
                                                            $moment
                                                                .unix(
                                                                    job.createdDateTime
                                                                )
                                                                .format(
                                                                    "DD/MM/YYYY"
                                                                )
                                                        }}
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td class="text-right">
                                                        <span
                                                            >£{{
                                                                job.cost
                                                            }}</span
                                                        >
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="text-left">
                                                        <span
                                                            >TIQI Fee ({{
                                                                tiqiFee
                                                            }}%)</span
                                                        >
                                                    </td>
                                                    <td>
                                                        {{
                                                            $moment
                                                                .unix(
                                                                    job.createdDateTime
                                                                )
                                                                .format(
                                                                    "DD/MM/YYYY"
                                                                )
                                                        }}
                                                    </td>
                                                    <td></td>
                                                    <td>
                                                        <v-icon
                                                            size="30"
                                                            color="success"
                                                            >mdi-chevron-right</v-icon
                                                        >
                                                    </td>
                                                    <td class="text-right">
                                                        <span
                                                            >£{{
                                                                parseFloat(
                                                                    (job.cost *
                                                                        tiqiFee) /
                                                                        100
                                                                ).toFixed(2)
                                                            }}</span
                                                        >
                                                    </td>
                                                </tr>
                                                <tr
                                                    v-for="item in transactions"
                                                    :key="item.name"
                                                >
                                                    <td class="text-left">
                                                        <span
                                                            v-if="
                                                                item.type ===
                                                                'clientTransferFunds'
                                                            "
                                                            >Client
                                                            Transfer</span
                                                        >
                                                    </td>
                                                    <td
                                                        v-if="
                                                            item.type ===
                                                            'clientTransferFunds'
                                                        "
                                                    >
                                                        {{
                                                            $moment
                                                                .unix(
                                                                    item.createdDateTime
                                                                )
                                                                .format(
                                                                    "DD/MM/YYYY"
                                                                )
                                                        }}
                                                    </td>
                                                    <td>
                                                        <span
                                                            v-if="
                                                                item.type ===
                                                                'clientTransferFunds'
                                                            "
                                                            >{{
                                                                job.client.name
                                                            }}</span
                                                        >
                                                    </td>
                                                    <td>
                                                        <v-icon
                                                            v-if="
                                                                item.type ===
                                                                'clientTransferFunds'
                                                            "
                                                            size="30"
                                                            color="success"
                                                            >mdi-chevron-right</v-icon
                                                        >
                                                    </td>
                                                    <td class="text-right">
                                                        <span
                                                            v-if="
                                                                item.type ===
                                                                'clientTransferFunds'
                                                            "
                                                            class="
                                                                success--text
                                                            "
                                                            >£{{
                                                                (
                                                                    item.amount /
                                                                    100
                                                                ).toFixed(2)
                                                            }}</span
                                                        >
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="text-left">
                                                        <span
                                                            >TIQI Fee ({{
                                                                tiqiFee
                                                            }}%)</span
                                                        >
                                                    </td>
                                                    <td>
                                                        {{
                                                            $moment
                                                                .unix(
                                                                    job.datePaymentReleased
                                                                )
                                                                .format(
                                                                    "DD/MM/YYYY"
                                                                )
                                                        }}
                                                    </td>
                                                    <td></td>
                                                    <td>
                                                        <v-icon
                                                            size="30"
                                                            color="error"
                                                            >mdi-chevron-left</v-icon
                                                        >
                                                    </td>
                                                    <td class="text-right">
                                                        <span
                                                            >£{{
                                                                parseFloat(
                                                                    (job.cost *
                                                                        tiqiFee) /
                                                                        100
                                                                ).toFixed(2)
                                                            }}</span
                                                        >
                                                    </td>
                                                </tr>
                                                <tr
                                                    v-for="item in transactions"
                                                    :key="item.names"
                                                >
                                                    <td class="text-left">
                                                        <span
                                                            v-if="
                                                                item.type ===
                                                                'clientReleaseFundsPartial'
                                                            "
                                                            >Release to
                                                            Trader</span
                                                        >
                                                    </td>
                                                    <td
                                                        v-if="
                                                            item.type ===
                                                            'clientReleaseFundsPartial'
                                                        "
                                                    >
                                                        {{
                                                            $moment
                                                                .unix(
                                                                    item.createdDateTime
                                                                )
                                                                .format(
                                                                    "DD/MM/YYYY"
                                                                )
                                                        }}
                                                    </td>
                                                    <td>
                                                        <span
                                                            v-if="
                                                                item.type ===
                                                                'clientReleaseFundsPartial'
                                                            "
                                                            >{{
                                                                job.trader
                                                                    .businessName
                                                            }}</span
                                                        >
                                                    </td>
                                                    <td>
                                                        <v-icon
                                                            v-if="
                                                                item.type ===
                                                                'clientReleaseFundsPartial'
                                                            "
                                                            size="30"
                                                            color="warning"
                                                            >mdi-chevron-left</v-icon
                                                        >
                                                    </td>
                                                    <td class="text-right">
                                                        <span
                                                            v-if="
                                                                item.type ===
                                                                'clientReleaseFundsPartial'
                                                            "
                                                            class="error--text"
                                                            >£{{
                                                                (
                                                                    item.amount /
                                                                    100
                                                                ).toFixed(2)
                                                            }}</span
                                                        >
                                                    </td>
                                                </tr>
                                                <tr
                                                    v-for="item in transactions"
                                                    :key="item.names"
                                                >
                                                    <td class="text-left">
                                                        <span
                                                            v-if="
                                                                item.type ===
                                                                'clientReleaseFundsRemainder'
                                                            "
                                                            >Release to
                                                            Trader</span
                                                        >
                                                    </td>
                                                    <td
                                                        v-if="
                                                            item.type ===
                                                            'clientReleaseFundsRemainder'
                                                        "
                                                    >
                                                        {{
                                                            $moment
                                                                .unix(
                                                                    item.createdDateTime
                                                                )
                                                                .format(
                                                                    "DD/MM/YYYY"
                                                                )
                                                        }}
                                                    </td>
                                                    <td>
                                                        <span
                                                            v-if="
                                                                item.type ===
                                                                'clientReleaseFundsRemainder'
                                                            "
                                                            >{{
                                                                job.trader
                                                                    .businessName
                                                            }}</span
                                                        >
                                                    </td>
                                                    <td>
                                                        <v-icon
                                                            v-if="
                                                                item.type ===
                                                                'clientReleaseFundsRemainder'
                                                            "
                                                            size="30"
                                                            color="warning"
                                                            >mdi-chevron-left</v-icon
                                                        >
                                                    </td>
                                                    <td class="text-right">
                                                        <span
                                                            v-if="
                                                                item.type ===
                                                                'clientReleaseFundsRemainder'
                                                            "
                                                            class="error--text"
                                                            >£{{
                                                                (
                                                                    item.amount /
                                                                    100
                                                                ).toFixed(2)
                                                            }}</span
                                                        >
                                                    </td>
                                                </tr>
                                                <tr
                                                    v-for="item in transactions"
                                                    :key="item.name"
                                                >
                                                    <td
                                                        v-if="item.remainder"
                                                        class="text-left"
                                                    >
                                                        <span
                                                            class="
                                                                font-weight-bold
                                                            "
                                                            >Balance:
                                                        </span>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td
                                                        v-if="item.remainder"
                                                        class="text-right"
                                                    >
                                                        <span
                                                            >£{{
                                                                (
                                                                    item.remainder /
                                                                    100
                                                                ).toFixed(2)
                                                            }}</span
                                                        >
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                    <!-- <v-col cols="12" v-if="job.dateRated === ''">
                                        <div class="body-2 mb-2">First time job? You are able to release job funds to the trader to eliminate the
                                            7 day wait period.
                                        </div>
                                        <v-btn block class="primary" @click="resolveFirstJob = true">Release funds to Trader</v-btn>
                                    </v-col> -->
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <!-- Dispute -->
                            <v-expansion-panel
                                v-if="job.status === 'escalated' || job.delete && job.paymentIntent"
                            >
                                <v-expansion-panel-header class="lightgrey">
                                    <v-col cols="2">
                                        <v-icon
                                            size="30"
                                            position="left"
                                            color="error"
                                            class="align-center"
                                            >tiqi-info</v-icon
                                        >
                                    </v-col>
                                    <v-col cols="12">
                                        <div
                                            v-if="job.status === 'escalated'"
                                            class="
                                                text-h6
                                                font-weight-regular
                                                darktext--text
                                                align-center
                                            "
                                        >
                                            Escalated
                                        </div>
                                        <div
                                            v-else
                                            class="
                                                text-h6
                                                font-weight-regular
                                                darktext--text
                                                align-center
                                            "
                                        >
                                            Job Deleted
                                        </div>
                                    </v-col>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-col cols="12">
                                        <div
                                            class="
                                                body-1
                                                font-weight-regular
                                                text-left
                                                pb-4
                                            "
                                        >
                                            Job Information
                                        </div>
                                        <div
                                            v-if="job.noShow"
                                            class="
                                                body-1
                                                font-weight-regular
                                                text-left
                                                pb-4
                                                error--text
                                            "
                                        >
                                            The buyer has reported this as a no show.
                                        </div>
                                        <v-simple-table>
                                            <template v-slot:default>
                                                <tbody>
                                                    <tr>
                                                        <td class="text-left">
                                                            Buyer
                                                        </td>
                                                        <td></td>
                                                        <td>
                                                            {{
                                                                job.client.name
                                                            }}
                                                        </td>
                                                        <td></td>
                                                        <td class="text-right">
                                                            {{
                                                                job.client.email
                                                            }}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-left">
                                                            Trader
                                                        </td>
                                                        <td></td>
                                                        <td>
                                                            {{
                                                                job.trader
                                                                    .businessName
                                                            }}
                                                        </td>
                                                        <td></td>
                                                        <td class="text-right">
                                                            {{
                                                                job.trader
                                                                    .userEmail
                                                            }}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-left">
                                                            Buyer comments
                                                        </td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td class="text-right">
                                                            {{
                                                                job.clientComments
                                                            }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                        <v-row>
                                            <v-col cols="12" v-if="!job.noShow">
                                                <v-btn
                                                    block
                                                    class="
                                                        primary
                                                        font-weight-bold
                                                    "
                                                    @click="
                                                        resolveDispute = true
                                                    "
                                                    >Resolve Dispute</v-btn
                                                >
                                            </v-col>
                                            <!-- <v-col cols="12">
                                                <div
                                                    class="
                                                        text-body
                                                        font-weight-regular
                                                        py-4
                                                    "
                                                >
                                                    Or, you can split the
                                                    remaining funds to
                                                    respective parties.
                                                </div>
                                                <v-btn
                                                    block
                                                    class="
                                                        yellow
                                                        font-weight-bold
                                                    "
                                                    @click="splitPayout = true"
                                                    >Split</v-btn
                                                >
                                            </v-col> -->
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12">
                                                <div
                                                    class="
                                                        text-body
                                                        font-weight-regular
                                                        py-4
                                                    "
                                                >
                                                    As a TIQI admin staff
                                                    member, you have the right
                                                    to refund all the job cost
                                                    to the buyer, please be
                                                    aware this is not
                                                    reversable. If you wish to
                                                    proceed, click Refund Buyer
                                                    below:
                                                </div>
                                                <v-btn
                                                    block
                                                    class="
                                                        error
                                                        font-weight-bold
                                                    "
                                                    @click="refundUser = true"
                                                    >Refund Buyer</v-btn
                                                >
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-card>
                </v-navigation-drawer>
            </v-row>
        </v-col>
    </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: "JobsComponent",
    data: () => ({
        activeJobId: "",
        allUsers: [],
        balance: "",
        buyers: [],
        buyerFees: 0,
        dialog: false,
        filterStatus: [
            "draft",
            "new",
            "sent",
            "rejected",
            "accepted",
            "inprogress",
            "completed",
            "dispute",
            "escalated",
        ],
        filterTraders: [],
        selectedTrader: null,
        filterBuyers: [],
        selectedBuyer: null,
        job: {
            client: {
                name: "Placeholder",
            },
            trader: {
                businessName: "Placeholder",
            },
            totalCost: "",
        },
        jobs: [],
        headers: [
            { text: "Name", value: "name", class: "lightgrey" },
            { text: "Status", value: "status", class: "lightgrey" },
            { text: "Value", value: "cost", class: "lightgrey" },
            { text: "Buyer", value: "client.name", class: "lightgrey" },
            { text: "Trader", value: "trader.businessName", class: "lightgrey" },
            { text: "Created at", value: "createdDateTime", class: "lightgrey" },
            { text: "Rejected at", value: "dateAccepted", class: "lightgrey" },
            { text: "Accepted at", value: "dateRejected", class: "lightgrey" },
            { text: "Started at", value: "dateStarted", class: "lightgrey" },
            { text: "Est. Duration", value: "length", class: "lightgrey", align: "center" },
            { text: "Completed at", value: "dateCompleted", class: "lightgrey", width: '20px' },
            { text: "Rated at", value: "rating", align: "center", class: "lightgrey" },
            { text: "Disputed on", value: "dateDisputed", class: "lightgrey" },
            { text: "Escalated on", value: "dateEscalated", class: "lightgrey" },
            { text: "Resolved on", value: "datePaymentReleased", class: "lightgrey" },
            { text: "Archived", value: "archived", class: "lightgrey" },
            { text: "Deleted", value: "delete", class: "lightgrey" },
            { text: "", value: "actions", class: "lightgrey" },
        ],
        loader: null,
        loading: false,
        props: ["search"],
        resolveFirstJob: false,
        refundUser: false,
        resolveDispute: false,
        releaseButton: true,
        search: "",
        splitPayout: false,
        status: [
            "draft",
            "new",
            "sent",
            "accepted",
            "inprogress",
            "completed",
            "dispute",
            "escalated",
        ],
        tiqiFee: "",
        transactions: [],
        traderFees: 0,
        userPassword: '',
        show: false,
    }),
    watch: {
        loader() {
            const l = this.loader;
            this[l] = !this[l];

            setTimeout(() => (this[l] = false), 30000);

            this.loader = null;
        },
    },
    computed: {
        ...mapGetters({
            GET_config: "GET_config",
        }),
        computedJobs() {
            let t = this;
            var jobs = t.jobs;
            //* Filter status
            jobs = jobs.filter(function (item) {
                if (item !== null) {
                    return t.filterStatus.includes(item.status);
                }
            });
            //* Filter traders
            jobs = jobs.filter(function (item) {
                if (item !== null && t.selectedTrader !== null) {
                    return t.selectedTrader.includes(item.trader.businessName);
                } else {
                    return jobs;
                }
            });
            //* Filter buyers
            jobs = jobs.filter(function (item) {
                if (item !== null && t.selectedBuyer !== null) {
                    return t.selectedBuyer.includes(item.client.name);
                } else {
                    return jobs;
                }
            });
            return jobs;
        },
        countArchive() {
            return this.jobs.filter((job) => job.archived).length;
        },
        countDeleted() {
            return this.jobs.filter((job) => job.delete).length;
        },
        countDraft() {
            return this.jobs.filter((job) => job.status === "draft").length;
        },
        countSent() {
            return this.jobs.filter((job) => job.status === "sent").length;
        },
        countInProgress() {
            return this.jobs.filter((job) => job.status === "inprogress")
                .length;
        },
        countRejected() {
            return this.jobs.filter((job) => job.status === "rejected").length;
        },
        countAccepted() {
            return this.jobs.filter((job) => job.status === "accepted").length;
        },
        countCompleted() {
            return this.jobs.filter((job) => job.status === "completed").length;
        },
        countDispute() {
            return this.jobs.filter((job) => job.status === "dispute").length;
        },
        countEscalated() {
            return this.jobs.filter((job) => job.status === "escalated").length;
        },
    },
    methods: {
        validatePassword() {
            let t = this
            t.$firebase.auth
                .signInWithEmailAndPassword(t.GET_currentUser.userEmail, t.userPassword)
                .then(async (data) => {
                    data
                    t.releaseButton = false
                })
                .catch(err => {
                    t.MIX_alert(-1, "Incorrect password", null, err)
                    t.releaseButton = true
                })
        },  
        next() {
            let t = this;
            t.isActive = false;
        },
        getJobs() {
            let t = this;
            t.$firebase.db.collection("jobs").onSnapshot((querySnapshot) => {
                t.jobs = [];
                querySnapshot.forEach((doc) => {
                    var job = doc.data();
                    job.id = doc.id;
                    job.status === "inprogress"
                        ? (job.status = "inprogress")
                        : "inprogress";
                    t.jobs.push(job);
                    if (job.client.name) {
                        t.filterBuyers.push(job.client.name);
                    }
                });
            });
        },
        getJob() {
            let t = this;
            t.$firebase.db
                .collection("jobs")
                .doc(t.activeJobId)
                .onSnapshot((doc) => {
                    var job = doc.data();
                    job.id = doc.id;
                    t.job = job;
                    job.rating = t.averageRating();
                });
        },
        getTransactions(id) {
            let t = this;
            t.transactions = [];
            t.$firebase.db
                .collection("transactions")
                .where("jobId", "==", id)
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        var document = {};
                        document = doc.data();
                        document.id = doc.id;
                        t.transactions.push(document);
                        t.transactions.reverse();
                    });
                });
        },
        openJob(item) {
            let t = this;
            t.dialog = true;
            t.activeJobId = item.id;
            t.getJob();
            t.getTransactions(item.id);
        },
        averageRating() {
            let t = this;
            const total = t.job.rating.reliability + t.job.rating.tidiness + t.job.rating.valueformoney + t.job.rating.workmanship + t.job.rating.expectations;
            return (total * 5) / 20
        },
        checkStatus(status) {
            switch (status) {
                case "completed":
                    return "success";
                case "draft":
                    return "darkgrey";
                case "dispute":
                    return "warning";
                case "inprogress":
                    return "primary";
                case "new":
                    return "primary";
                case "sent":
                    return "primary";
                case "accepted":
                    return "accepted";
                case "escalated":
                    return "error";
                default:
                    return "grey darken-2";
            }
        },
        getAllTraders() {
            let t = this;
            t.$firebase.db
                .collection("business")
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        const document = doc.data();
                        t.filterTraders.push(document.businessName);
                    });
                });
        },
        getAllUsers() {
            let t = this;
            t.$firebase.db
                .collection("users")
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        const document = doc.data();
                        if (document.business) {
                            t.allUsers.push(document);
                        }
                    });
                });
        },
        releaseBuyerFunds() {
            let t = this;
            t.loading = true;
            t.loader = t.loading;
            //* CALL API TO RELEASE
            const url =
                "https://europe-west1-tiqi-app-369301.cloudfunctions.net/refundPayout?id=" + t.job.paymentIntent;
            t.$axios
                .post(url)
                .then((response) => {
                    const updateFirebaseJob = {
                        payment: {
                            paid: t.job.cost,
                            remainder: 0,
                        },
                        status: "completed",
                    };
                    const transactionRecord = {
                        jobId: t.job.id,
                        type: "adminReleaseAllFundsToBuyer",
                        amount: t.job.cost,
                        remainder: 0,
                        status: "completed",
                    };
                    //* UPDATE FIREBASE
                    t.$firebase.db
                        .collection("jobs")
                        .doc(t.job.id)
                        .update(updateFirebaseJob)
                        .then((result) => result)
                        .catch((error) => error);
                    //* CREATE TRANSACTION ENTRY IN FIREBASE
                    t.$firebase.db
                        .collection("transactions")
                        .add(transactionRecord);
                    t.MIX_alert(1, "Refunded buyer:" + t.job.client.name + " " + t.job.cost + "successfully.", response, null);
                    t.loading = false;
                    t.refundUser = false;
                })
                .catch((error) => {
                    if (
                        error.response.data.error.raw.code ===
                        "charge_already_refunded"
                    ) {
                        t.MIX_alert(
                            -1,
                            "Refund has already been actioned.",
                            null,
                            error
                        );
                    } else {
                        t.MIX_alert(-1, "Error releasing funds", null, error);
                    }
                    t.updateUser = false;
                    t.loading = false;
                    t.refundUser = false;
                });
        },
        resolveEscalation() {
            let t = this;
            t.loading = true;
            t.loader = t.loading;
            const updateFirebaseJob = {
                payment: {
                    available: t.job.payment.available + t.job.payment.remainder,
                    remainder: 0,
                },
                status: "completed",
                dateResolved: t.$moment().format('X')
            };
            t.$firebase.db
                .collection("jobs")
                .doc(t.job.id)
                .update(updateFirebaseJob)
                .catch((error) => error);
            t.MIX_alert(1, "Funds made available to trader.", null, null);
            t.resolveDispute = false
        },
        releaseTraderFunds() {
            let t = this;
            t.loading = true;
            t.loader = t.loading;
            //* CALL API TO RELEASE FUNDS TO TRADER & UPDATE FIREBASE
            const url =
                "https://europe-west1-tiqi-app-369301.cloudfunctions.net/createPayout?id=" +
                t.job.trader.stripeAccountId;
            const getBank = t.allUsers.filter(
                (user) => user.business.name === t.job.trader.businessName
            );
            const stripePayout = {
                amount: t.job.payment.remainder,
                destination: getBank[0].stripeBankNumber,
                source_type: "card",
            };
            t.$axios
                .post(url, stripePayout)
                .then(() => {
                    const updateFirebaseJob = {
                        payment: {
                            paid: stripePayout.amount + t.job.payment.remainder,
                            remainder: 0,
                        },
                        status: "completed",
                    };
                    const transactionRecord = {
                        jobId: t.job.id,
                        type: "clientReleaseFundsRemainder",
                        amount: stripePayout.amount,
                        remainder: 0,
                        status: "",
                    };
                    //* UPDATE FIREBASE
                    t.$firebase.db
                        .collection("jobs")
                        .doc(t.job.id)
                        .update(updateFirebaseJob)
                        .then((result) => result)
                        .catch((error) => error);
                    //* CREATE TRANSACTION ENTRY IN FIREBASE
                    t.$firebase.db
                        .collection("transactions")
                        .add(transactionRecord);
                    t.MIX_alert(1, "Funds released to trader.", null, null);
                })
                .catch((error) => {
                    t.MIX_alert(-1, "Error releasing funds", null, error);
                    t.loading = false;
                });
            t.resolveDispute = false
        },
        resolveFirstJobWait() {
            let t = this;
            t.loading = true;
            t.loader = t.loading;
            //* CALL API TO RELEASE FUNDS TO TRADER & UPDATE FIREBASE
            const url =
                "https://europe-west1-tiqi-app-369301.cloudfunctions.net/createPayout?id=" +
                t.job.trader.stripeAccountId;
            const getBank = t.allUsers.filter(
                (user) => user.business.name === t.job.trader.businessName
            );
            debugger
            const stripePayout = {
                amount: t.job.cost * 100,
                destination: getBank[0].stripeBankNumber,
                source_type: "card",
            }   
            t.$axios
                .post(url, stripePayout)
                .then(() => {
                    const updateFirebaseJob = {
                        datePaymentReleased: t.$moment().format('X'),
                        payment: {
                            paid: stripePayout.amount,
                            remainder: 0,
                        },
                        status: "completed",
                    };
                    const transactionRecord = {
                        jobId: t.job.id,
                        type: "resolveFirstJobWait",
                        amount: stripePayout.amount,
                        remainder: 0,
                        status: "",
                    };
                    //* UPDATE FIREBASE
                    t.$firebase.db
                        .collection("jobs")
                        .doc(t.job.id)
                        .update(updateFirebaseJob)
                        .then((result) => result)
                        .catch((error) => error);
                    //* CREATE TRANSACTION ENTRY IN FIREBASE
                    t.$firebase.db
                        .collection("transactions")
                        .add(transactionRecord);
                    t.MIX_alert(1, "Funds released to trader.", null, null);
                    t.loading = false;
                })
                .catch((error) => {
                    t.MIX_alert(-1, "Error releasing funds", null, error);
                    t.loading = false;
                });
        },
        splitFunds() {
            // let t = this
            // debugger

        }
    },
    mounted() {
        let t = this;
        t.tiqiFee = t.GET_config.tiqiFee;
        t.getAllUsers();
        t.getJobs();
        t.getAllTraders();
    },
};
</script>

<style>
.v-data-table-header th {
    white-space: nowrap !important;
}
.v-card {
    cursor: pointer !important;
}

v-chip {
    border-radius: 2em;
}
</style>