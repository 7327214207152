import App from './App.vue'
import moment from 'moment';
import router from './router.js'
import store from './store.js'
import Vue from 'vue'
import vuetify from './vuetify.js'
import axios from 'axios';
import "@/assets/css/styles.min.css";
import JsonCSV from 'vue-json-csv'

require('./modules/firebase/firebase_import.js');
require('./components/progressBar/progressBar_import.js');
require('./modules/alertbox/alertbox_import.js');

Vue.config.productionTip = false
Vue.prototype.$moment = moment;
Vue.prototype.$axios = axios;

import mixins from './mixins.js';
Vue.use(mixins);

import Navigation from "./components/NavigationComponent";
Vue.component('Navigation', Navigation);

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts);

Vue.component('downloadCsv', JsonCSV)
Vue.component('apexchart', VueApexCharts)

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
