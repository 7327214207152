<template>
    <div class="d-flex justify-center flex-column">
        <v-progress-linear
            v-if="loading === true"
            indeterminate
            color="primary"
        ></v-progress-linear>
        <v-overlay :value="overlay">
            <v-col cols="12" class="d-flex flex-column align-center">
                <v-progress-circular
                    indeterminate
                    class="mb-4"
                    size="64"
                ></v-progress-circular>
                <div class="text-center">We're just logging you in...</div>
            </v-col>
        </v-overlay>
        <v-row class="mt-0" no-gutters>
            <v-col cols="12">
                <v-window v-model="step">
                    <v-window-item :value="0">
                        <v-form
                            ref="registerUser"
                            v-model="valid"
                            lazy-validation
                        >
                            <v-text-field
                                solo
                                :background-color="inputBackgroundColor"
                                class="mb-1"
                                v-model="userForm.userFirstName"
                                label="First name"
                                hide-details="auto"
                                :rules="firstNameRules"
                                append-icon="tiqi-person"
                                required
                            ></v-text-field>
                            <v-text-field
                                solo
                                :background-color="inputBackgroundColor"
                                class="mb-12"
                                v-model="userForm.userLastName"
                                label="Last name"
                                hide-details="auto"
                                :rules="lastNameRules"
                                append-icon="tiqi-person"
                                required
                            ></v-text-field>
                            <v-text-field
                                solo
                                :background-color="inputBackgroundColor"
                                class="mb-8"
                                v-model.trim="userForm.userEmail"
                                label="Email"
                                hide-details="auto"
                                append-icon="tiqi-mail"
                                :rules="emailRules"
                                required
                            ></v-text-field>
                        </v-form>
                        <div class="text-right"></div>
                        <v-row class="my-4">
                            <v-col cols="6">
                                <v-btn
                                    block
                                    depressed
                                    large
                                    @click="MIX_go('/')"
                                    class="mt-3 grey lighten-2"
                                >
                                    BACK
                                </v-btn>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="6">
                                <v-btn
                                    block
                                    depressed
                                    @click="next"
                                    :loading="loading1"
                                    :disabled="loading1"
                                    large
                                    class="mt-3 primary"
                                >
                                    NEXT
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-window-item>
                    <v-window-item :value="1">
                        <v-form
                            ref="userPassword"
                            v-model="valid1"
                            lazy-validation
                        >
                            <v-text-field
                                solo
                                :background-color="inputBackgroundColor"
                                hide-details="auto"
                                class="mb-2"
                                v-model.trim="password"
                                label="Password"
                                :append-icon="
                                    show ? 'tiqi-eye' : 'tiqi-invisible'
                                "
                                :type="show ? 'text' : 'password'"
                                @click:append="show = !show"
                                :rules="passwordRules"
                                required
                            ></v-text-field>
                            <v-text-field
                                solo
                                :background-color="inputBackgroundColor"
                                hide-details="auto"
                                class="mb-2"
                                v-model.trim="confirmPassword"
                                label="Confirm password"
                                :append-icon="
                                    show1 ? 'tiqi-eye' : 'tiqi-invisible'
                                "
                                :type="show1 ? 'text' : 'password'"
                                @click:append="show1 = !show1"
                                :rules="[
                                    password === confirmPassword ||
                                        'Password must match',
                                ]"
                                required
                            ></v-text-field>
                        </v-form>
                        <!--Register button -->
                        <v-row class="my-4">
                            <v-col cols="6">
                                <v-btn
                                    block
                                    depressed
                                    large
                                    @click="step--"
                                    class="mt-3 grey lighten-2"
                                >
                                    BACK
                                </v-btn>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="6">
                                <v-btn
                                    block
                                    depressed
                                    large
                                    @click="register"
                                    :loading="loading"
                                    :disabled="loading"
                                    class="mt-3 primary"
                                >
                                    REGISTER
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-window-item>
                    <!-- Error message -->
                    <v-window-item :value="3">
                        <div
                            class="pa-4 text-center"
                            v-if="accountCreated === true"
                        >
                            <v-progress-linear
                                indeterminate
                                color="primary"
                            ></v-progress-linear>
                            <h3 class="title font-weight-light mb-2">
                                Welcome
                            </h3>
                            <span class="caption grey--text">
                                Thanks for signing up! You will be redirected to
                                the home page
                            </span>
                        </div>
                        <div class="pa-4 text-center" v-else>
                            <h3 class="title font-weight-light mb-2">
                                Oops, Something went wrong
                            </h3>
                            <h4 v-if="registerErrorMessage != null">
                                {{ registerErrorMessage }}
                            </h4>
                            <span class="caption grey--text"
                                >Please try again!</span
                            >
                        </div>
                    </v-window-item>
                </v-window>
            </v-col>
        </v-row>
        <div class="text-body text-center">
            Already have an account?
            <span
                style="cursor: pointer"
                class="primary--text font-weight-bold"
                @click="MIX_go('/')"
                >Login</span
            >
        </div>
        <!-- end of USER-INPUT -->
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    name: "Register",
    data() {
        return {
            accountCreated: true,
            adminRegCode: "",
            confirmPassword: "",
            confirmRules: [(v) => !!v || "Confirm password required"],
            dialog: false,
            emailRules: [
                (v) => !!v || "E-mail is required",
                (v) =>
                    /^\w+([-+.']\w+)*@?(vindico.net|tiqi.co.uk|tiqi.app)$/.test(
                        v.trim()
                    ) || "E-mail must be valid",
            ],
            firstNameRules: [(v) => !!v || "First name is required"],
            lastNameRules: [(v) => !!v || "Last name is required"],
            inputBackgroundColor: "white",
            loader1: null,
            loading: false,
            loading1: false,
            overlay: false,
            password: "",
            passwordRules: [
                (v) => !!v || "Password is required",
                (v) =>
                    (v.length >= 8 &&
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
                            v
                        )) ||
                    "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character",
            ],
            registerErrorMessage: null,
            show: false,
            show1: false,
            step: 0,
            userForm: {
                userId: "",
                userFirstName: "",
                userLastName: "",
                userEmail: "",
                userLevel: "",
                userType: "Admin",
                userAcceptTerms: false,
                userCreatedDateTime: "",
                userEmailVerified: false,
                userVerified: false,
            },
            valid: true,
            valid1: true,
        };
    },
    watch: {
        loader1() {
            const l = this.loader1;
            this[l] = !this[l];
            setTimeout(() => (this[l] = false), 3000);
            this.loader1 = null;
        },
        overlay(val) {
            val &&
                setTimeout(() => {
                    this.overlay = false;
                }, 60000);
        },
    },
    computed: {
        ...mapGetters({
            GET_currentUser: "GET_currentUser",
        }),
    },
    methods: {
        ...mapActions({
            ACT_currentUser: "ACT_currentUser",
        }),
        next: async function () {
            if (this.$refs.registerUser.validate()) {
                if (this.$route.params.id === "admin") {
                    let result = await this.MIX_FIREBASE_checkAdminRegCode(
                        this.adminRegCode
                    );
                    if (result.code === 1 && result.data === true) {
                        this.userForm.userLevel = "SA";
                        this.step++; // * move to the next step
                        console.log(
                            "this.form.userLevel = " + this.form.userLevel
                        );
                    } else {
                        this.MIX_alert(
                            -1,
                            "Admin Registration Code is Incorrect, please try again."
                        );
                    }
                } else {
                    this.step++; // * move to the next step
                }
            } else {
                // ! did not pass validation
                this.MIX_alert(
                    -1,
                    "Fill out all the fields correctly in order to proceed.",
                    null,
                    null
                );
            }
        },
        register() {
            var t = this;
            t.overlay = true;
            t.MIX_alert(2, "Creating account...", null, null);
            this.step++; // * move to the next step
            t.userForm.userEmail = t.userForm.userEmail.toLowerCase();
            t.userForm.userLevel = "SA";
            // * create user
            t.$firebase.auth
                .createUserWithEmailAndPassword(
                    t.userForm.userEmail,
                    t.password
                )
                .then((user) => {
                    t.userForm.userId = user.user.uid; // * assign firebase auth user id to firebase user in collection
                    t.userForm.userCreatedDateTime = t.$moment().format("x");
                    t.userForm.userEmailVerified = false
                    t.userForm.userVerified = false
                    // * add to the admin users collection
                    t.$firebase.db
                        .collection("adminUsers")
                        .doc(user.user.uid)
                        .set(t.userForm)
                        .then((Result) => {
                            console.log("result: " + Result);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                    t.accountCreated = true;
                    this.MIX_alert(1, "Your account was created.", null, null);
                    // * send verification userEmail to user
                    var currentUser = t.$firebase.auth.currentUser;
                    currentUser
                        .sendEmailVerification()
                        .then(() => {
                            t.MIX_alert(
                                2,
                                "An account verification email has been sent to you.",
                                null,
                                null
                            );
                        })
                        .catch((error) => {
                            console.log(
                                "send verification email error: " + error
                            );
                            t.MIX_alert(-1, error, null, null);
                            t.overlay = false;
                        });
                    t.overlay = false;
                    t.MIX_go('/')
                })
                .catch((err) => {
                    t.registerErrorMessage = err;
                    t.accountCreated = false;
                    console.log("firebase registering error: " + err);
                    t.MIX_alert(-1, err, null, null);
                    t.overlay = false;
                });
        },
    },
};
</script>
