import { mapGetters } from 'vuex'

const mixins = {
    data: () => ({
        token: '',
    }),
    computed: {
        ...mapGetters({
            GET_currentUser: 'GET_currentUser',
            // GET_authToken: 'GET_authToken',
        }),
    },
    methods: {
        MIX_calculateFees(job) {
            if (!job.feePaid) {
                const coupon = job.discountCode;
                const coupons = this.GET_config?.coupons;
                const fees = Object.keys(coupons).includes(coupon) ? coupons[coupon] : this.GET_config.default;
                
                return {
                    fees,
                    buyerFee: parseFloat(job.cost) * parseFloat(fees.buyer) * 100,
                    totalCost: (parseFloat(job.cost) + (parseFloat(job.cost) * parseFloat(fees.buyer))).toFixed(2) * 100,
                    sellerFee: parseFloat(job.cost) * parseFloat(fees.seller) * 100,
                    payoutValue: (parseFloat(job.cost) * 100) - parseFloat((((parseFloat(job.cost) * parseFloat(fees.seller).toFixed(2))) * 100)),
                }
            } else {
                return {
                    fees: job.feePaid,
                    buyerFee: parseFloat(job.cost) * parseFloat(job.feePaid.buyer) * 100,
                    totalCost: (parseFloat(job.cost) + (parseFloat(job.cost) * parseFloat(job.feePaid.buyer))).toFixed(2) * 100,
                    sellerFee: parseFloat(job.cost) * parseFloat(job.feePaid.seller) * 100,
                    payoutValue: (parseFloat(job.cost) * 100) - parseFloat((((parseFloat(job.cost) * parseFloat(job.feePaid.seller).toFixed(2))) * 100)),
                }
            }
        },
        MIX_formatDateTime: function (date, formatIn, formatOut) {
            if (date !== '') {
                return this.$moment(date, formatIn).format(formatOut);
            } else {
                return ''
            }
        },
        MIX_authToken(token) {
            this.ACT_authToken(token)
        },
        MIX_go(path) {
            this.$router.push(path).catch(err => {
                console.log('Route error: ' + err)
            })
            this.open = false;
        },
        MIX_back() {
            this.$router.go(-1)
        },
        MIX_terms() {
            alert('terms and conditions')
        },
        MIX_alertJS(message) {
            alert(message);
        },
        MIX_updateCurrentUser(userID) {
            let t = this
            var currentUser = []
            t.$firebase.db
                .collection('users')
                .doc(userID)
                .get()
                .then((doc) => {
                    if (doc.exists) {
                        var document = doc.data()
                        document.id = doc.id
                        currentUser = document
                        t.$store.commit('SET_currentUser', currentUser)
                    } else {
                        currentUser = null
                        // t.MIX_alert(-1, 'Account not found.', null, null)
                    }
                })
        },
    },
}

export default {
    install(Vue) {
        Vue.mixin(mixins)
    },
}
