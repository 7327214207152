<template>
    <v-container fluid>
        <v-col cols="12">
            <v-row>
                <v-col cols="6" class="px-0">
                    <v-text-field
                        solo
                        elevation="0"
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-col>
            </v-row>
        </v-col>
        <v-data-table
            :headers="headers"
            :items="users"
            :items-per-page="10"
            :search="search"
            class="elevation-1 mt-6"
        >
            <template v-slot:[`item.userEdit`]="{ item, on, attrs }">
                <v-btn
                    @click="openUser(item)"
                    depressed
                    class="primary--text white"
                    v-bind="attrs"
                    v-on="on"
                    >Open<v-icon>mdi-chevron-right</v-icon></v-btn
                >
            </template>
        </v-data-table>
        <!-- User panel -->
        <v-navigation-drawer v-model="dialog" fixed app right width="600">
            <!-- OVERLAY FOR UPDATE USER -->
            <v-overlay :value="updateUser">
                <v-col cols="12" class="d-flex flex-column align-center">
                    <v-progress-circular
                        indeterminate
                        class="mb-4"
                        size="64"
                    ></v-progress-circular>
                    <div class="text-center">Updating user, please wait...</div>
                </v-col>
            </v-overlay>
            <!-- OVERLAY FOR ACTIVATE USER -->
            <v-overlay :value="activateUser" opacity="0.85">
                <v-col
                    cols="12"
                    class="d-flex flex-column align-center"
                >
                    <div
                        class="
                            text-h4
                            error--text
                            font-weight-bold
                            pb-4
                        "
                    >
                        Warning!
                    </div>
                    <div class="body-1 pb-8">
                        Are you sure you want to activate the user {{ user.userFirstName + ' ' + user.userLastName }}
                    </div>
                    <v-row>
                        <v-col cols="6">
                            <v-btn
                                block
                                class="darkgrey"
                                @click="activateUser = false"
                                >Cancel</v-btn
                            >
                        </v-col>
                        <v-col cols="6">
                            <v-btn
                                block
                                class="primary"
                                @click="activateAdminUser()"
                                :loading="loading"
                                :disabled="loading"
                                >ACTIVATE</v-btn
                            >
                        </v-col>
                    </v-row>
                </v-col>
            </v-overlay>
            <v-toolbar dark class="darktext mb-4">
                <v-row class="d-flex align-center justify-space-between pa-4">
                    <div class="text-h4 font-weight-light white--text">
                        {{ user.userFirstName }} {{ user.userLastName }}
                    </div>
                    <v-btn icon @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-row>
            </v-toolbar>
            <v-simple-table>
                <template v-slot:default>
                    <tbody>
                        <tr>
                            <td class="font-weight-bold">Id:</td>
                            <td>{{ user.userId }}</td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold">Account Type:</td>
                            <td>
                                {{ user.userType }}
                            </td>
                        </tr>
                        <tr v-if="user.userType === 'Admin'">
                            <td class="font-weight-bold">Status:</td>
                            <td v-if="!user.userVerified" class="error--text">
                                Not Verified
                            </td>
                            <td v-else class="success--text">
                                Verified
                            </td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold">User:</td>
                            <td>
                                {{ user.userFirstName }} {{ user.userLastName }}
                            </td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold">Email:</td>
                            <td>{{ user.userEmail }}</td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold">Telephone:</td>
                            <td>{{ user.userTelephone }}</td>
                        </tr>
                        <tr v-if="user.profileCompleted">
                            <td class="font-weight-bold">
                                Profile completion:
                            </td>
                            <td>{{ user.profileCompleted }}%</td>
                        </tr>
                        <tr v-if="user.business">
                            <td class="font-weight-bold">Business Address:</td>
                            <td>
                                {{ user.business.name }}<br />
                                {{ user.business.address.line1 }}<br />
                                {{ user.business.address.line2 }}<br />
                                {{ user.business.address.county }}<br />
                                {{ user.business.address.postcode }}<br />
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
            <v-spacer class="py-6"></v-spacer>
            <v-simple-table>
                <template v-slot:default>
                    <tbody v-if="user.stripeAccountId">
                        <tr>
                            <td class="font-weight-bold">
                                Stripe Account Details
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold">Stripe Account Id:</td>
                            <td>{{ user.stripeAccountId }}</td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold">
                                Stripe Bank Number:
                            </td>
                            <td>{{ user.stripeBankNumber }}</td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold">
                                Linked Stripe Person Id:
                            </td>
                            <td>{{ user.stripePersonId }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
            <v-row class="ma-4">
                <v-btn
                    v-if="user.userType === 'Admin' && !user.userVerified"
                    block
                    large
                    class="primary white--text"
                    @click="activateUser = true"
                    >ACTIVATE USER</v-btn
                >
                <v-btn
                    v-if="user.userType === 'Admin' && user.userVerified"
                    block
                    large
                    disabled
                    >ACTIVATE USER</v-btn
                >
            </v-row>
            <v-row class="ma-4">
                <v-btn
                    v-if="userDisabled"
                    block
                    large
                    class="primary white--text"
                    @click="changeUser('enable')"
                    >ENABLE USER</v-btn
                >
                <v-btn
                    v-if="!userDisabled"
                    block
                    large
                    class="error white--text"
                    @click="changeUser('suspend')"
                    >SUSPEND USER</v-btn
                >
            </v-row>
        </v-navigation-drawer>
    </v-container>
</template>

<script>
export default {
    name: "Users",
    data: () => ({
        activeUserId: "",
        activateUser: false,
        dialog: false,
        firebaseUser: {
            disabled: false,
        },
        headers: [
            // { text: "ID", value: "userId", width: "50px", class: "lightgrey" },
            { text: "User Type", value: "userType", class: "lightgrey" },
            {
                text: "Stripe ID",
                value: "stripeAccountId",
                width: "50px",
                class: "lightgrey",
            },
            { text: "First Name", value: "userFirstName", class: "lightgrey" },
            { text: "Last Name", value: "userLastName", class: "lightgrey" },
            { text: "Email", value: "userEmail", class: "lightgrey" },
            { text: "Telephone", value: "userTelephone", class: "lightgrey" },
            { text: "", value: "userEdit", class: "lightgrey" },
        ],
        loader: false,
        loading: false,
        search: "",
        updateUser: false,
        user: {
            business: {
                name: "",
            },
            isDisabled: '',
        },
        users: [],
        userDisabled: false,
    }),
    methods: {
        changeUser(value) {
            let t = this;
            t.updateUser = true;
            if (value === "suspend") {
                t.userDisabled = true;
                t.firebaseUser.disabled = true;
            } else if (value === "enable") {
                t.userDisabled = false;
                t.firebaseUser.disabled = false;
            }
            const url =
                "https://europe-west1-tiqi-app-369301.cloudfunctions.net/firebaseAdmin-updateFirebaseUser?id=" +
                t.user.userId +
                "&value=" +
                t.firebaseUser.disabled;
            t.$axios
                .post(url)
                .then((response) => {
                    t.MIX_alert(
                        1,
                        "Person updated successfully",
                        response,
                        null
                    );
                    t.updateUser = false;
                })
                .catch((error) => {
                    t.MIX_alert(-1, "Error updating person", null, error);
                    t.updateUser = false;
                });
            t.$firebase.db
                .collection("users")
                .doc(t.user.userId)
                .update({ isDisabled: t.firebaseUser.disabled })
                .then(result => result)
                .catch(error => error)
        },
        getAllUsers() {
            let t = this;
            t.$firebase.db
                .collection("users")
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        t.users.push(doc.data());
                    });
                });
            t.$firebase.db
                .collection("adminUsers")
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        t.users.push(doc.data());
                    });
                });
        },
        openUser(item) {
            let t = this;
            t.dialog = true;
            t.activeUserId = item.userId;
            if(item.userType === 'Admin') {
                t.getAdminUser();
            } else {
                t.getUser();
            }
        },
        getAdminUser() {
            let t = this;
            t.$firebase.db
                .collection("adminUsers")
                .doc(t.activeUserId)
                .onSnapshot((doc) => {
                    const user = doc.data();
                    user.id = doc.id;
                    t.user = {};
                    t.user = user;
                });
        },
        getUser() {
            let t = this;
            t.$firebase.db
                .collection("users")
                .doc(t.activeUserId)
                .onSnapshot((doc) => {
                    const user = doc.data();
                    user.id = doc.id;
                    t.user = {};
                    t.user = user;
                });
        },
        activateAdminUser() {
            //! CRAIG DO THIS TO ACTIVATE ADMIN USER IN FIREBASE
            let t = this
            t.$firebase.db.collection("adminUsers").doc(t.user.id).update({ userVerified: true })
            t.activateUser = false
        }
    },
    mounted() {
        let t = this;
        t.getAllUsers();
    },
};
</script>