import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store';

import Home from '@/views/Home.vue'
import Jobs from '@/views/Jobs.vue'
import Login from '@/views/auth/login.vue'
import Register from '@/views/auth/register.vue';
import Setup from '@/views/Setup.vue';
import Users from '@/views/Users.vue'

Vue.use(VueRouter)

const routes = [
    { path: '/', name: 'Login', component: Login },
    { path: '/home', name: 'Home', component: Home, meta: { requiresAuth: true } },
    { path: '/jobs', name: 'Jobs', component: Jobs, meta: { requiresAuth: true } },
    { path: '/register', name: 'Register', component: Register },
    { path: '/setup', name: 'Setup', component: Setup, meta: { requiresAuth: true } },
    { path: '/users', name: 'Users', component: Users, meta: { requiresAuth: true } },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    if (requiresAuth) {
        let checkUser = store.getters['firebase_auth_store/GET_FIREBASE_currentUser'];
        // navigator.serviceWorker.register('/service-worker.js').then(reg => {
        //     reg.update();
        // });
        if (!checkUser) {
            next("/");
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router
