const alert_store = {
  namespaced: true,
  state: {
    alert: {
      code: -1,
      color: 'error',
      timeout: 3000,
      message: 'Error',
      // positionTop:false,
      // positionBot:false,
      data: null,
      error: null,
      show: false,
    },
  },
  mutations: {
    setAlert(state, data) {
      state.alert = data;
    },
  },
  getters: {
    currentAlert(state) {
      return state.alert;
    },
  },
};
export default alert_store;
