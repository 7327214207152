<template>
    <v-navigation-drawer
        permanent
        expand-on-hover
        class="nav noprint"
        app
        clipped
        v-if="GET_currentUser != null"
    >
        <v-list nav dense pa-0 ma-0>
            <v-list-item
                v-for="(item, index) in computedTabs"
                :key="index"
                link
                :to="item.path"
                active-class="primery"
            >
                <v-list-item-icon class="black--text">
                    <v-icon class="navicons black--text">{{
                        item.icon
                    }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="black--text">{{
                    item.name
                }}</v-list-item-title>
            </v-list-item>
        </v-list>
        <template v-slot:append>
            <v-list nav dense pa-0 ma-0>
                <v-list-item
                    v-for="(item, index) in computedBottomTabs"
                    :key="index"
                    link
                    :to="item.path"
                    @click.stop="item.func"
                    active-class="primary"
                >
                    <v-list-item-icon class="black--text">
                        <v-icon class="navicons black--text">{{
                            item.icon
                        }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="black--text">{{
                        item.name
                    }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </template>
    </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: "Navigation",
    data: () => ({
        navigationTabs: [
            {
                name: "Home",
                path: "/home",
                icon: "tiqi-home",
                userLevels: [],
            },
            {
                name: "Jobs",
                path: "/jobs",
                icon: "tiqi-toolbox",
                userLevels: [],
            },
        ],
        navigationBottomTabs: [
            {
                name: "TIQI Setup",
                path: "/setup",
                icon: "tiqi-idea",
            },
            {
                name: "User Management",
                path: "/users",
                icon: "tiqi-settings",
            },
            {
                name: "Logout",
                path: "/",
                icon: "tiqi-person",
            },
        ],
    }),
    computed: {
        ...mapGetters({
            GET_currentUser: "GET_currentUser",
        }),
        computedTabs: function () {
            let t = this;
            const newTabs = [];
            t.navigationTabs.forEach((tab) => {
                newTabs.push(tab);
            });
            return newTabs;
        },
        computedBottomTabs: function () {
            let t = this;
            const newTabs = [];
            t.navigationBottomTabs.forEach((tab) => {
                newTabs.push(tab);
            });
            return newTabs;
        },
    },
};
</script>