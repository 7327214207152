<template>
    <div
        class="d-fliex justify-center"
        style="width: calc(100vw - 30px) !important"
    >
        <v-overlay :value="overlay">
            <v-col cols="12" class="d-flex flex-column align-center">
                <v-progress-circular
                    indeterminate
                    class="mb-4"
                    size="64"
                ></v-progress-circular>
                <div class="text-center">We're just logging you in...</div>
            </v-col>
        </v-overlay>
        <!-- start of USER-INPUT -->
        <v-row class="mt-0" no-gutters>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <v-form
                    ref="loginForm"
                    v-on:submit.prevent="login()"
                    class="mt-3"
                    v-model="valid"
                    lazy-validation
                >
                    <v-text-field
                        solo
                        elevation="0"
                        class="mb-3"
                        v-model.trim="userEmail"
                        :background-color="inputBackgroundColor"
                        label="Email"
                        append-icon="tiqi-mail"
                        hide-details="auto"
                        :rules="emailRules"
                        required
                    ></v-text-field>
                    <v-text-field
                        solo
                        elevation="0"
                        class="mb-12"
                        v-model.trim="userPassword"
                        :background-color="inputBackgroundColor"
                        label="Password"
                        :append-icon="show ? 'tiqi-eye' : 'tiqi-invisible'"
                        :type="show ? 'text' : 'password'"
                        @click:append="show = !show"
                        :rules="passwordRules"
                        hide-details="auto"
                        required
                        v-on:keyup.enter="login()"
                    ></v-text-field>
                </v-form>
            </v-col>
        </v-row>
        <v-row class="justify-center" no-gutters>
            <v-col
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
                class="d-flex justify-space-around flex-column align-center"
            >
                <v-btn
                    class="primary mt-2 mb-1 white--text"
                    style="width: 45% !important"
                    :large="largeButton"
                    :small="smallButton"
                    @click.native="login()"
                >
                    LOGIN
                </v-btn>
                <!-- <div class="mt-2">
                    <router-link
                        class="
                            primary--text
                            font-weight-bold
                            text-decoration-none
                        "
                        :to="{ path: 'reset' }"
                    >
                        Forgot password?
                    </router-link>
                </div> -->
            </v-col>
        </v-row>
        <!-- end of USER-INPUT -->
    </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
    name: "Login",
    data: () => ({
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        inputBackgroundColor: "white",
        loading: false,
        overlay: false,
        passwordRules: [(v) => !!v || "Password is required"],
        show: false,
        userEmail: "",
        userPassword: "",
        valid: true,
    }),
    computed: {
        denseInput() {
            switch (true) {
                case this.$vuetify.breakpoint.xsOnly:
                    return true;
                default:
                    return false;
            }
        },
        largeButton() {
            switch (true) {
                case this.$vuetify.breakpoint.xsOnly:
                    return false;
                default:
                    return true;
            }
        },
        smallButton() {
            switch (true) {
                case this.$vuetify.breakpoint.xsOnly:
                    return true;
                default:
                    return false;
            }
        },
    },
    methods: {
        ...mapActions({
            ACT_currentUser: "ACT_currentUser",
        }),
        checkCurrentUser(userID) {
            let t = this;
            var currentUser = [];
            t.$firebase.db
                .collection("adminUsers")
                .doc(userID)
                .get()
                .then((doc) => {
                    if (doc.exists) {
                        var document = doc.data();
                        document.id = doc.id;
                        currentUser = document;
                        if(document.userEmailVerified === true && document.userVerified === true) {
                            t.ACT_currentUser(currentUser);
                            t.$router.push("/home").catch((err) => console.log("err: " + err));
                        } else {
                            t.MIX_alert(-1, "Please verify your email address and/or a member of TIQI needs to approve the account.", null, null);
                            t.overlay = false;
                        }
                    } else {
                        currentUser = null;
                        t.MIX_alert(-1, "Account not found.", null, null);
                    }
                });
            return currentUser;
        },
        checkValidationUser(data) {
            let t = this
            if(data.user.emailVerified === true) {
                t.$firebase.db.collection("adminUsers").doc(data.user.uid).update({ userEmailVerified: true })
                t.MIX_FIREBASE_currentUser(data.user);
            }
        },
        login() {
            let t = this;
            t.overlay = true;
            t.userEmail = t.userEmail.toLowerCase();
            if (t.$refs.loginForm.validate()) {
                t.$firebase.auth
                    .signInWithEmailAndPassword(t.userEmail, t.userPassword)
                    .then(async (data) => {
                        t.loading = true;
                        t.checkValidationUser(data)
                        var user = await t.checkCurrentUser(data.user.uid);
                        if (!user) {
                            t.MIX_alert(-1, "Please verify your email address and/or a member of TIQI needs to approve the account.", null, null);
                            t.overlay = false;
                        }
                    })
                    .catch((err) => {
                        console.log("err: " + err);
                        t.MIX_alert(-1, err, null, null);
                        t.overlay = false;
                    });
            } else {
                t.overlay = false;
                t.MIX_alert(
                    -1,
                    "The email password combination is incorrect.",
                    null,
                    null
                );
            }
        },
    },
};
</script>

<style scoped></style>
