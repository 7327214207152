<template>
    <v-container fluid>
        <!-- <v-row>
            <v-col cols="12" class="d-flex">
                <v-icon
                    size="40"
                    position="left"
                    color="primary"
                    class="align-center"
                    >tiqi-toolbox</v-icon
                >
                <div class="text-h4 font-weight-light primary--text pl-4">
                    Jobs
                </div>
            </v-col>
        </v-row> -->
        <v-row>
            <v-col cols="12" class="d-flex flex-column">
                <JobsComponent />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import JobsComponent from "../components/JobsComponent";

export default {
    name: "Jobs",
    data: () => ({}),
    components: { JobsComponent },
    computed: {
        ...mapGetters({
            GET_currentUser: "GET_currentUser",
        }),
    },
};
</script>
