<template>
    <v-container fluid>
        <v-row class='d-flex align-center justify-start'>
            <v-col cols="4">
                <v-data-table
                    :headers="headers"
                    :items="setupValues"
                    :items-per-page="5"
                    class="elevation-1 rounded-sm"
                    hide-default-footer
                    hide-default-header
                >
                    <template v-slot:[`item.actions`]="{ item, on, attrs }">
                        <td class='d-flex justify-end align-end'>
                            <v-icon
                                class="text-h4"
                                color="primary"
                                v-bind="attrs"
                                v-on="on"
                                @click="openSetupItem(item)"
                                >mdi-cog</v-icon
                            >
                        </td>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <!-- Setup panel -->
        <v-navigation-drawer v-model="dialog" fixed app right width="600">
            <v-toolbar dark class="darktext mb-4">
                <v-row class="d-flex align-center justify-space-between pa-4">
                    <div class="text-h4 font-weight-light white--text">
                        {{ setupItem.item }}
                    </div>
                    <v-btn icon @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-row>
            </v-toolbar>
            <v-simple-table>
                <template v-slot:default>
                    <tbody>
                        <tr>
                            <td class="text-body font-weight-bold" width="180">
                                {{ setupItem.item }}
                            </td>
                            <td>
                                <v-text-field
                                    v-if="setupItem.item === 'TIQI Fee %'"
                                    :placeholder="GET_config.tiqiFee.toString()"
                                    v-model="newTiqiFee"
                                    type="number"
                                >
                                </v-text-field>
                                <v-text-field
                                    v-if="setupItem.item === 'Minimum Job Cost'"
                                    :placeholder="GET_config.minJobCost.toString()"
                                    v-model="newMinJob"
                                    type="number"
                                >
                                </v-text-field>
                                <v-text-field
                                    v-if="setupItem.item === 'Coupon Code'"
                                    :placeholder="GET_config.coupon.toString()"
                                    v-model="newCouponCode"
                                >
                                </v-text-field>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
            <v-col cols="12">
                <v-row class="pa-8">
                    <v-btn block class="primary" @click="updateConfig(setupItem)"
                        >Update</v-btn
                    >
                </v-row>
            </v-col>
        </v-navigation-drawer>
    </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "SetupComponent",
    data: () => ({
        config: {},
        dialog: false,
        headers: [
            { text: "Setup Item", value: "item", align: 'start' },
            { text: "Setup Actions", value: "actions", align: 'end' },
        ],
        newMinJob: "",
        newTiqiFee: "",
        newCouponCode: "",
        setupValues: [
            {
                item: "TIQI Fee %",            
            },
            {
                item: "Minimum Job Cost",
            },
            {
                item: "Coupon Code",
            },
        ],
        setupItem: {},
    }),
    computed: {
        ...mapGetters({
            GET_config: "GET_config",
        }),
    },
    methods: {
        openSetupItem(item) {
            let t = this;
            t.dialog = true;
            t.setupItem = item;
            console.log(item);
        },
        updateConfig(setupItem) {
            let t = this;
            if(setupItem.item === "TIQI Fee %") {
                t.config.tiqiFee = parseInt(t.newTiqiFee);
            } else if (setupItem.item === "Minimum Job Cost") {
                t.config.minJobCost = parseInt(t.newMinJob);
            } else {
               t.config.coupon = t.newCouponCode; 
            }
            t.$firebase.db
                .collection("config")
                .doc("fees")
                .set(t.config)
                .then((response) => {
                    response;
                    t.MIX_alert(1, "Updated TIQI Config", null, null);
                })
                .catch((error) => {
                    error;
                    t.MIX_alert(
                        -1,
                        "An arror has occured, please try again",
                        null,
                        null
                    );
                });
        },
    },
    mounted() {
        let t = this;
        t.config = t.GET_config;
    },
};
</script>