import { mapActions } from 'vuex'
// import axios from 'axios';

// * GLOBAL MIXIN
const mixin = {
    methods: {
        ...mapActions('firebase_auth_store', ['ACT_FIREBASE_currentUser']),
        MIX_FIREBASE_currentUser(user) {
            this.ACT_FIREBASE_currentUser(user)
        },
        MIX_FIREBASE_logout() {
            let t = this
            t.$firebase.auth.signOut().then(() => {
                t.$router.push('/login').catch(err => {
                    console.log('Route error: ' + err)
                })
                this.ACT_FIREBASE_currentUser(null)
            })
        },
    },
    mounted() { },
}

export default {
    install(Vue) {
        Vue.mixin(mixin)
    },
}
