import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBMn9q78kdu73dP4VASK9Ypc6380rINtgQ",
  authDomain: "tiqi-app-369301.firebaseapp.com",
  projectId: "tiqi-app-369301",
  storageBucket: "tiqi-app-369301.appspot.com",
  messagingSenderId: "960122509709",
  appId: "1:960122509709:web:a18185ac5e4b581c4745c4",
  measurementId: "G-QY9RTMG8YS"
};

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}
const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const currentUser = auth.currentUser;
const fv = firebase.firestore.FieldValue;

//https://europe-west1-agriculture-research.cloudfunctions.net/checkAdminRegCode?code=Farming2021!

export { db, storage, auth, currentUser, fv };
