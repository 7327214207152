<template>
  <v-container class="d-flex align-center justify-center" fluid>
      <v-row>
        <v-col cols="12" class='d-flex'>
            <v-icon size="40" position="left" color="primary" class="align-center">tiqi-settings</v-icon>
            <div class="text-h4 font-weight-light primary--text pl-4">User Management</div>
        </v-col>
        <v-col cols="12">
            <UsersComponent />
        </v-col>
      </v-row>
  </v-container>
</template>

<script>
import UsersComponent from "../components/UsersComponent";
export default {
    name: "UsersPage",
    data: () => ({}),
    components: {
        UsersComponent
    },
};
</script>