<template>
    <div
        class="
            viewport-maxwidth viewport-maxheight
            px-4
            d-flex
            align-center
            justify-center
            mt--10
        "
    >
        <div
            class=""
            style="width: 100% !important; margin-top: 50px !important"
        >
            <v-row class="d-flex justify-center" no-gutters>
                <v-col
                    cols="12"
                    xs="12"
                    sm="12"
                    md="3"
                    lg="3"
                    xl="3"
                    class="mt-8 text-left"
                >
                    <div class="text-h5 font-weight-light darkgrey--text">
                        Welcome to
                    </div>
                    <div class="text-h5 font-weight-light primary--text">
                        <b>TIQI</b>
                    </div>
                    <div class="text-body-2 grey--text text--darken-2 mb-12">
                        Portal
                    </div>
                </v-col>
            </v-row>
            <v-row class="d-flex justify-center" no-gutters>
                <v-col
                    cols="12"
                    xs="12"
                    sm="8"
                    md="4"
                    lg="3"
                    xl="3"
                    class="d-flex align-center justify-center"
                >
                    <firebase-auth-login />
                </v-col>
            </v-row>
            <v-row class="text-center mt-4 viewport-maxwidth">
                <v-col class="grey--text text--darken-2">
                    Don't have an account?
                    <router-link
                        class="
                            primary--text
                            font-weight-bold
                            text-decoration-none
                        "
                        :to="{ path: 'register' }"
                        >Register</router-link
                    >
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    methods: {
        ...mapActions({ ACT_currentUser: 'ACT_currentUser' })
    },
    mounted() {
        this.ACT_currentUser(null)
    }
}
</script>
